
import React, { useState } from 'react';
import homeData from '../../_shared/homeData';
import { getIcon } from '../contents/icons';

const baseUrl = window.location.origin;
const { about, menuItems, games, hours, drinks, coFounders, foodMenu } = homeData;
const Home = props => {
  const REACT_APP_HAK_HOMICIDE_AT_THE_HEIST_PWD = process.env.REACT_APP_HAK_HOMICIDE_AT_THE_HEIST_PWD;
  const [showMenu, setShowMenu] = useState(false);
  const [search, setSearch] = useState('');
  const menuTimer = 1000;
  const submitSearch = () =>{
    if (search.toUpperCase() === REACT_APP_HAK_HOMICIDE_AT_THE_HEIST_PWD) {
      setSearch('');
      props.setIsLogin('true');
      localStorage.setItem('isLogin', 'true');
    }else{
      setSearch('');
    }
  };
  const executeScroll = item => {
    document.getElementById(item).scrollIntoView();
    setShowMenu(false);
  };

  return (
    <div className='home-container' >
      <div className='hamburger-menu w-100'>
        <div>
          <img className='menu-icon pointer' width="12%" alt="menu" src={getIcon.menuIcon} onClick={() => setShowMenu(true)} />
        </div>
        {showMenu && <div className={`menu-container ${showMenu === true ? 'show-menu' : 'hide-menu'}`}>
          <img className='menu-icon pointer' width="12%" alt="close" src={getIcon.closeIcon} onClick={() => {
            setShowMenu('mid');
            setTimeout(() => setShowMenu(false), menuTimer);
          }} />
          <div>
            <ul className='list menu-items'>
              {menuItems.map((item,ind) => (
                <li
                  key={ind}
                  onMouseDown={() => {
                    props.setIsLogin('false');
                    localStorage.setItem('isLogin', 'false');
                  }}
                  onClick={()=>{executeScroll(item);}}>
                    <span className='pointer'>{item.toUpperCase()}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>}
      </div>
      <div className='hamburger-menu w-100'>
        <img src={getIcon.barcadeLogo} alt="logo"/>
      </div>
      <div className='hamburger-menu w-100'>
          <div className={`d-flex search-container ${props.isLogin === 'true' ? 'login-search' : 'logout-search'}`}>
          <img src={baseUrl + '/heist-assets/ic-Search.svg'} width="5%" alt="search"/>
          <input type='search' className='search-field' placeholder='SEARCH...'
          value={search}
          readOnly = {props.isLogin === 'true'}
          onKeyDown={e => e.key === 'Enter' && submitSearch()}
          onChange={e => setSearch(e.target.value)} />
          <img className={`${props.isLogin === 'false' && 'arrow-icon'}`} width="7%" src={baseUrl + '/heist-assets/ic-Arrow.svg'} alt="arrow"  onClick={submitSearch}/>
        </div>
      </div>
      {props.isLogin === 'false' &&
        <>
          <div className='w-100'>
            <img src={getIcon.banner} width="100%" alt="banner"/>
          </div>
          <div className='divider' style={{marginTop: 0}}></div>
          <div className='home-block w-100' id='about'>
            <div className="d-flex justify-content-between">
              <h1>ABOUT</h1>
              <img src={getIcon.aboutIcons} width="63%" alt="About"/>
            </div>
            <div className='block-content'>{about}
            </div>
          </div>
          <div className='w-100'>
            <img src={getIcon.nickyPhoto} width="100%" alt="Nicky"/>
          </div>
          <div className='divider'></div>
          <div className='w-100'>
            <img src={getIcon.vincentPhoto} width="100%" alt="Vincent"/>
          </div>
          <div className='home-block w-100'>
            <ul className='list pink co-founders'>
              {coFounders.map((founder,ind) => (
                <li key={ind}>{founder}</li>
              ))}
            </ul>
          </div>
          <div className='home-block w-100' id='location'>
            <div className='d-flex justify-content-between'>
              <h1>LOCATION</h1>
              <img src={getIcon.location} width="40%" alt="Location"/>
            </div>
            <div className='block-content'>
              Nicky’s (b)Arcade
            </div>
          </div>
          <div className='home-block w-100' id='contact'>
            <div className='d-flex justify-content-between'>
              <h1>CONTACT</h1>
              <img src={getIcon.contact} width="45%" alt="Contact"/>
            </div>
            <div className='block-content'>
              Nicky’s (b)Arcade
            </div>
          </div>
          <div className='home-block w-100' id='hours'>
            <div className="d-flex justify-content-between">
              <h1>HOURS</h1>
              <img src={getIcon.hours} width="60%" alt="Hours"/>
            </div>
            <div className='block-content'>
              <table className='w-100'>
                <tbody>
                {hours.map((row,ind) => (
                  <tr key={ind}>{
                    row.map((data,index) => <td key={index}> {data} </td>)
                  }</tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='home-block w-100' id="games">
            <h1>GAMES</h1>
            <div className='block-content'>
              <table className='w-100 games-list'>
                <tbody>
                {games.map((row, rowInd) => (
                  <tr key={rowInd}>{
                    row.map((data, dataInd) => (
                      <td key={dataInd}>
                        {data} {(rowInd === 1 && dataInd === 0) && <span className='pink'>*</span>}
                      </td>
                    ))
                  }</tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='home-block w-100' id='drink menu'>
            <div className='d-flex justify-content-between block-content'>
              <div>
                <h1>Drink Menu</h1>
                <div className='drink-menu'>
                  {drinks.map((item,ind) => (
                    <div key={ind}>{item}</div>
                  ))}
                </div>
              </div>
              <img src={getIcon.drink} width="30%" alt="Drink"/>
            </div>
          </div>
          <div className='home-block w-100' id='bar food menu'>
            <h1>Bar Food Menu</h1>
            <div className='d-flex justify-content-between block-content'>
              <ul className="list">
                {foodMenu.map((food,ind) => (
                  <li key={ind}>{food}</li>
                ))}
              </ul>
              <img src={getIcon.barFood} width="33%" alt="Bar Food Men="/>
            </div>
          </div>
          <footer>
            {`(C) Nicky's (b) Arcade`}
          </footer>
        </>
      }
    </div>);
};

export default Home;
