
import React, { useEffect, useState } from 'react';
import iconsConfig from '../builder/icons';
import {
  addUserMessage,
  addResponseMessage,
  isWidgetOpened,
  toggleMsgLoader,
  renderCustomComponent,
  Widget,
  markAllAsRead
} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import './rcw.css';
import './desktop.css';
import { qna, addDelayedResponseMessage, replaceTokens, getSavedMessages, saveMessages } from '../../_shared/utils';
import Builder from '../builder/Builder';
import Home from '../home/Home';

const messageDetective = () => <Builder config={iconsConfig?.slice(iconsConfig.length - 1, iconsConfig.length)} />;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const saveChatMessage = (type, message, time = Date.now()) => {
  const savedMessages = getSavedMessages();
  savedMessages.push({
    type: type,
    time,
    message});
  saveMessages(savedMessages);
};

const detectiveText = 'detective letter';
const hydrateChatBox = () => {
  const delay = 50;//ms
  const savedMessages = getSavedMessages();
  savedMessages.forEach((msg, index) => {
    if (savedMessages.length === index + 1){
    setTimeout(markAllAsRead, delay * index + 1);
    }
    if (msg.type === 'user') {
      setTimeout(() => addUserMessage(msg.message), delay * index);
    }
    if (msg.type === 'response') {
      setTimeout(() => addResponseMessage(msg.message), delay * index);
    }
    if (msg.type === 'attachment' && msg.message === detectiveText) {
      setTimeout(() => renderCustomComponent(messageDetective, { id: msg.id}), (delay * index) + delay);
    }
  });
};

const Desktop = () => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin') || 'false');
  const [chatToggle, setChatToggle] = useState(false);
  const [showDetective, setShowDetective] = useState(localStorage.getItem('isChatCorrect') || 'false');
  const [displayPdf, setDisplayPdf] = useState(iconsConfig?.slice(0, iconsConfig.length - 1));
  const [badge, setBadge] = useState(Number(localStorage.getItem('isChatNotification')) || 0);

  useEffect(() => {
    if(chatToggle){
      setBadge(0);
      localStorage.removeItem("isChatNotification");
    }
    showDetective === 'true' ? setDisplayPdf(iconsConfig) : setDisplayPdf(iconsConfig?.slice(0, iconsConfig.length - 1));
  }, [showDetective,chatToggle,badge]);

  useEffect(() => {
    const savedMessages = getSavedMessages();
    if (savedMessages.length === 0) {
      saveChatMessage('response', 'When you know who killed my brother, send me their name and I’ll take care of the rest.');
    }
    hydrateChatBox();
  }, []);

  const handleNewUserMessage = async newMessage => {
    try {
      saveChatMessage('user', newMessage);
      toggleMsgLoader();
      const chat = qna(newMessage);
      toggleMsgLoader();
      if (chat.toLowerCase() === 'no good match found in kb.') {
        addDelayedResponseMessage('I’m not sure if it was them. Try looking at the clues and let me know who you think is guilty.');
      } else if (chat.toLowerCase().indexOf('{{incorrect}}') !== -1) {
        saveChatMessage('response', replaceTokens(chat));
        addDelayedResponseMessage(replaceTokens(chat), 0);
      } else {
        const timer = 10000;
        saveChatMessage('response', replaceTokens(chat));
        addDelayedResponseMessage(replaceTokens(chat), 0);
        sleep(timer).then(() => {
          renderCustomComponent(messageDetective, { id: detectiveText });
          setBadge(1);
          saveChatMessage('attachment', detectiveText);
          localStorage.setItem('isChatNotification', 1);
          localStorage.setItem('isChatCorrect', 'true');
          setShowDetective('true');
        });
      }
    } catch (error) {
      toggleMsgLoader();
      console.warn(error);
      addDelayedResponseMessage('[Away Message] I’m not at the computer right now, sorry!');
    }
  };
  const getCustomLauncher = handleToggle => (
    <>
      {!chatToggle &&
        <div className='button-area'>
          {badge > 0 && <span className="custom-badge">{badge}</span>}
          <button className='chat-toggle' onClick={handleToggle}>Chat</button>
        </div>
      }
    </>
  );
  return (
    <div id='draggable-bounds' className={`desktop-container ${isLogin === 'true' ? 'login-bg' : 'logout-bg'} ${chatToggle && 'chatToggle'}`} >
      {!chatToggle &&
        <>
          <Home isLogin={isLogin} setIsLogin={setIsLogin} />
          {isLogin === 'true' &&
            <>
              <hr className='login-bar' />
              <Builder config={displayPdf} />
            </>
          }
        </>
      }
      {isLogin === 'true' &&
        <div onClick={() => isWidgetOpened()}>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            title="CHAT"
            fullScreenMode={true}
            handleToggle={args => setChatToggle(args)}
            launcher={handleToggle => getCustomLauncher(handleToggle)}
            subtitle={null}
            senderPlaceHolder={'TYPE HERE...'}
          />
        </div>
      }
    </div>);
};

export default Desktop;
