
export const chat = {
    inputs: {
        0: 'Sidney Baker, Sidney Baker, Sid Baker, Sidney, Sid',
        1: 'Vincent, Vincent Wagner, Vinny, You',
        2: 'Owl, Rabbit, Donkey, Bear, Tiger, Piglet, Christopher',
        3: 'Dolores Pryce , Dolores',
        4: 'Loic Townsend, Loic',
        5: 'Theodore “Teddy” King, Theodore King, Teddy King, Theodore, Teddy',
        6: 'Franchesca Leone, Franchesca',
        7: 'Adina Morita, Adina',
        8: 'Jamie Sweetum',
        9: 'Memphis',
        10: 'Jace Light',
        11: 'Dee Dee Maxwell',
        12: 'The Dutchess',
        13: 'Mrs. Green',
        14: 'Sir Thomas Evans',
        15: 'Mike Taylors',
        16: 'Jamie Orpington',
        17: 'Mordekai Scotts',
        18: 'Sammy Schindler',
        19: 'David Stonewall',
        20: 'Leticia',
        21: 'Gigi',
        22: 'Frenchie',
        23: 'Maria',
        24: 'Daisy',
        25: 'Mrs. Martinez',
        26: 'Any other answer...'},
    outputs: {
        //response for sid "CORRECT ANS"
        '0': '{{Correct}}You’re right. It was Sid. Things have to move fast. I’ll get back to you.',
        //reponse for vincent
        '1': '{{Incorrect}}I wouldn’t kill my own brother.',
        //response for animal code
        '2': '{{Incorrect}}The code name won’t do it. I need to know their real name.',
        //response for Dolores
        '3': '{{Incorrect}}I don’t know. It doesn’t seem right to me that it was Dolores. Give the evidence I sent you another look.',
        //response for Loic
        '4': '{{Incorrect}}That can’t be it. See if there’s something you missed and let me know who else you think it was.',
        //response for Teddy King
        '5': '{{Incorrect}}Are you sure? I don’t think it was Teddy. Really comb through the details again and let me know who else you come up with.',
        //response for Franches
        '6': '{{Incorrect}}No, I don’t think it can be Franchesca. Take another look at the evidence I sent you and send me another name.',
        //response for Adina
        '7': '{{Incorrect}}That can’t be it. Give the evidence I sent you another look.',
        //response for Jamie
        '8': '{{Incorrect}}Sweetum is not a real name, no way. I need their real name.',
        //response for Memphis
        '9': '{{Incorrect}}I don’t have the aliases in front of me. I don’t know who Memphis is. I need their name.',
        //response for Jace light
        '10': '{{Incorrect}}I don’t think that’s it, but I need their real name, not an alias.',
        //response for maxwell
        '11': '{{Incorrect}}I need their real name.',
        //response for Dutchess
        '12': '{{Incorrect}}Fancy. I don’t think so, but I’m not sure who that is, either.',
        //response for green
        '13': '{{Incorrect}}This isn’t some board game. I need their real name, not an alias.',
        //response for Thomas Evans
        '14': '{{Incorrect}}Really? That’s not a real person, that’s an alias. I need their real name.',
        //response for Mike Taylors
        '15': '{{Incorrect}}I don’t know who that is. That’s just an alias. I need their legal name.',
        //response for Jamie Orpington
        '16': '{{Incorrect}}I’m not sure. Can you send me their real name? I don’t know anyone’s alias.',
        //response for Mordekai Scotts
        '17': '{{Incorrect}}If we’re going to catch this person, I need their real name, not their alias.',
        //response for Sammy Schindler
        '18': '{{Incorrect}}Can you send me their real name, not an alias? I don’t know who Sammy Schindler is.',
        //response for David Stonewall
        '19': '{{Incorrect}}I don’t know who David is. What’s their real name?',
        //response for Leticia
        '20': '{{Incorrect}}I need their real name, not an alias. Who is Leticia, really?',
        //response for Gigi
        '21': '{{Incorrect}}Gigi… I need their real name. I don’t know whose alias this is.',
        //response for Frenchie
        '22': '{{Incorrect}}Maybe, but I need their real name, not an alias.',
        //response for Maria
        '23': '{{Incorrect}}That’s an alias and I need the person’s real name.',
        //response for Daisy
        '24': '{{Incorrect}}I need their real name.',
        //response for Martinez
        '25': '{{Incorrect}}I don’t think that’s it, but I need their real name to be sure one way or the other.',
        //response for rest of all inputs
        '26': '{{Incorrect}}That doesn’t help. It has to be one of our main suspects.'}};
