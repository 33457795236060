
const homeData = {
    // About content for home page
  about: `If you love arcade games from the 1980s and 1990s, you’ll love Nicky’s (b)Arcade bar. Bar owners Nicky and Vincent Wagner
    wanted to create a fun place where patrons 21 years old and over could drink tasty craft beers and original cocktails while
    playing their favorite arcade games. Nicky’s (b)Arcade includes not only arcade games but also the largest selection of
    vintage pinball machines in the state. Nicky and Vincent hope you’ll come by to relive that feeling of having a blast
    playing games and hanging out with friends at arcades.`,
    // List item for menu
    menuItems: ['about', 'location', 'contact', 'hours', 'games', 'drink menu', 'bar food menu'],
    // List of games in arcade
    games: [
        //Hotog Time is Nicky's favorite game
        ['Meteors', 'Mortal Kombat'], ['Hotdog Time', 'Outlast'],
        ['Earwig', 'R*bert'], ['Protector', 'Space Colonizers'],
        ['Dig Dug 2', 'Space Anxiety'], ['Monkey King', 'Sungate'],
        ['Monkey King Junior', 'Street Fighter II'], ['Toader', 'Great Breakout'],
        ['Pac Man', 'Time Heist'], ['Jumping Jim', ''],
        ["* = Nicky's Favorite"]],
    // working hour nicky's arcade
    hours: [
        ['Monday – Thursday', '6 PM – 2 AM'],['Friday – Saturday', '5 PM – 2 AM'],['Sunday', '2 PM – 1 AM']],
    // Available drinks in arcade
    drinks: ['Over 25 craft beers — stouts, ales,sours, pilsners and more.', 'Specialty Cocktails and Well Drinks'],
    // Founders of arcade
    coFounders: ['Co-founders', 'Nicholas and', 'Vincent Wagner.'],
    // Dishes available in arcade
    foodMenu: ['Hot Dogs', 'Popcorn', 'Nachos', 'Fried Pickles', 'Pizza — veggie and pepperoni', 'Funnel Cakes', 'Ice Cream Bars']};

    export default homeData;
